import gql from "graphql-tag"

import { buildPath, historyRef } from "../../routes"
import { Cookie } from "../../cookie"
import { authenticatedUserFragment, graphQLErrorHandler } from "utils/index"
import { getQuery } from "hooks"

export const SIGN_IN_USER = gql`
  mutation AuthenticateWebUser($input: AuthenticateWebUserInputType!) {
    authenticateWebUser(input: $input) {
      webAuthToken
      user {
        ...userFragment
      }
    }
  }
  ${authenticatedUserFragment}
`

export const ACCEPT_MANAGER_INVITE = gql`
  mutation AcceptManagerInvite($input: AcceptManagerInviteInputType!) {
    acceptManagerInvite(input: $input) {
      id
      didAccept
    }
  }
`

export const SEND_PASSWORD_LESS_AUTH_LINK = gql`
  mutation SendPasswordlessAuthLink(
    $input: SendPasswordlessAuthLinkInputType!
  ) {
    sendPasswordlessAuthLink(input: $input) {
      success
    }
  }
`

export function checkIfFromOffersSite() {
  return (
    typeof document !== "undefined" &&
    document.location.search === "?fromOffers"
  )
}

export function getGiftSafely() {
  const giftCookie = Cookie.get("gift")
  if (!giftCookie) return null
  try {
    const giftObject = JSON.parse(giftCookie)
    return giftObject
  } catch (e) {
    return null
  }
}

export function beginSessionOrRedeemGift(redirectTo: string | null) {
  if (getGiftSafely()) {
    historyRef.current.replace(buildPath('fundsGiftRedemption'))
  } else if (redirectTo) {
    Cookie.delete('redirectTo')
    historyRef.current.replace(decodeURIComponent(redirectTo))
  } else {
    historyRef.current.replace("/")
  }
}

export function authenticateWithMagicLinkFactory(
  { history, setErrorMessage, sendPasswordlessAuthLink }:
    { history: any, setErrorMessage: Function, sendPasswordlessAuthLink: Function }
) {
  let redirectTo = Cookie.get('redirectTo')
  const handle = Cookie.get('followHandle')

  const queryParams = getQuery()
  const giftInProgress = queryParams.get('giftInProgress') || Cookie.get('giftInProgress')
  const redirectToQueryParam = queryParams.get('redirectTo')
  if (redirectToQueryParam) {
    redirectTo = redirectToQueryParam
  }
  return (email: string) => {
    const input: {
      email: string
      origin?: string
      redirectTo?: string
      handle?: string
      amount?: number
      frequency?: number
      firstDay?: string
      activationDate?: string
    } = { email, origin: 'web' }

    if (handle) input.handle = handle
    if (redirectTo) input.redirectTo = redirectTo
    if (giftInProgress) {
      const { amount, frequency, firstDay, activationDate } = JSON.parse(giftInProgress)
      if (amount) input.amount = amount
      if (frequency) input.frequency = frequency
      if (firstDay) input.firstDay = firstDay
      if (activationDate) input.activationDate = activationDate
    }
    sendPasswordlessAuthLink({ variables: { input } })
      .then(() => history.push(buildPath('authLinkSent')))
      .catch(graphQLErrorHandler(setErrorMessage))
  }
}